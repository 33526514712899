<template>
  <div>
    <div class="mt-2">
      <h1 style="font-size: 40px; color: #121212; font-weight: 100;">Welcome to</h1>
      <h1 style="font-size: 40px; color: #086ad8;">Content Delivery Network</h1>      
    </div>
    <div>
      <va-card class="flex xs12 md12 mt-5 card-radius">
        <div class="row">
          <div class="flex xs7">
            <h2>Speed Test</h2>
            <span style="font-size: 30px; color: #aeaeae;">Your Link Speed<span style="font-size: 50px; font-weight: 700; color: #086ad8;"> {{speedValue}} </span>MBps</span>
            <div>Upstream</div>
            <div v-if="!isUpstream">
              <va-select
                v-model="upstream"
                textBy="id"
                :options="upstream_obj"
                :error="!!UpstreamErrors.length"
                :error-messages="UpstreamErrors"
                searchable
              />          
            </div>
            <div v-if="isUpstream">
              <va-input
                v-model="upstream"
                type="text"
                placeholder="127.0.0.1 - 8001"
              />          
            </div>            
          </div>
          <div class="flex xs5">
            <center>
              <va-button color="#086ad8" style="margin-bottom: -20px; margin-top: 40px; width: 192px;" @click="checkSpeed()">Run Speed Test </va-button>
            </center>
            <center>
              <va-button v-if="!isUpstream" outline color="#c7c7c7" class="field_button" @click="fieldChange()">+ Add Upstream</va-button>
              <va-button v-if="isUpstream" outline color="#c7c7c7" class="field_button" @click="fieldCancel()">Cancel</va-button>
            </center>
          </div>
        </div>
      </va-card>
      <div class="row flex md12 mt-5 ml-1">
        <va-card class="flex md3 ma-2 card-radius">
          <center style="font-size: 32px; color: #086ad8; font-weight: 700;">{{cpu_used}}</center>
          <center>CPU</center>
          <center class="mt-3 card-button"><va-button color="#086ad8" style="border-radius: 6px;" @click="cpu_refresh()">Refresh</va-button></center>
        </va-card>
        <va-card class="flex md3 ma-2 card-radius">
          <center style="font-size: 32px; color: #086ad8; font-weight: 700;">{{ram_used}}</center>
          <center>RAM</center>
          <center class="mt-3 card-button"><va-button color="#086ad8" style="border-radius: 6px;" @click="ram_refresh()">Refresh</va-button></center>
        </va-card>
        <div class="flex md5 mt-5">
          <center style="font-size: 30px; color: #aeaeae;">Version</center>
          <center style="font-size: 30px; color: #086ad8;"> {{title}} </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import VueSpeedometer from "vue-speedometer"
import io from "socket.io-client";
import config from '../../i18n/en.json'

// import {getLineChartData} from './TrafficData'
Vue.use(vueResource)
//var socket = io.connect("http://192.168.200.125:12000");
export default {
  name: 'dashboard',
  components: { VueSpeedometer }, 
  data(){
    return{
        isReached100:false,
        trafficValue : [],
        serverIP : '',
        masterCDN : '',
        upstream: '',
        upstream_obj: [],
        UpstreamErrors: [],
        trafficData : {},
        speedValue : 0,
        lodderToggle : false,
        cpu_used: '',
        ram_used: '',
        title: '',
        isUpstream: false
    }
  },

  beforeCreate (){
    //api get cal for server ip, master cdn, traffic 
    this.$http.get(config.menu.host + 'dashboard')
    .then(response => {
      this.upstream_obj = response.body.upstream
      this.getSystemInfo();
      this.getVersion();
      // this.serverIP = response.body.server_ip
      // this.masterCDN = response.body.master_cdn
      // this.trafficValue = response.body.current_traffic
      //this for traffic chart
      // this.trafficData = getLineChartData(this.trafficValue)
      // this.getData()
    })
  },

  computed: {
    getSpeed(){
      return this.speedValue
    }
  },

  methods: {  
    checkSpeed(){
      if(this.upstream == ''){
        Vue.notify({ text: 'Please fill the upstream field', type: 'error'})
        return
      }
      if(this.isUpstream){
        var upstream = this.upstream.split("-")
        if(upstream[1] === undefined){
          Vue.notify({ text: 'Please add port in the field', type: 'error'})
          return
        }
        var payload = {
          "upstream" : upstream[0].trim(),
          "port"     : upstream[1].trim()
        }
      }else{
        var upstream = this.upstream.split(" ")
        var payload = {
          "upstream" : upstream[0]
        }  
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'dashboard', payload).then(response => {
        loader.hide()
        this.speedValue = Number(response.body.speed.toFixed(2))
	this.upstream = ''
      })
      //socket.emit("speed_test",payload)
      //socket.on("data", fetchedData => {
        //this.speedValue = fetchedData
      //})
    },
    fieldChange () {
      this.upstream = ''
      this.isUpstream = true
    },
    fieldCancel() {
      this.upstream = ''
      this.isUpstream = false
    },
    getSystemInfo () {
      var role = Vue.$cookies.get('userRole')
      if(role == 'SYS_ADMIN'){
        this.$http.get(config.menu.host + 'setting/system_information').then(response => {
          var responseBody = response.body
          this.cpu_used = responseBody.cpu_used
          this.ram_used = responseBody.ram_used
        })
      }
    },
    getVersion(){
      this.$http.get(config.menu.host + 'auth/version/')
      .then(response => {
        this.title = response.body.version
      })
    },
    cpu_refresh(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'setting/system_information').then(response => {
        loader.hide()
        this.cpu_used = response.body.cpu_used
      })
    },
    ram_refresh(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'setting/system_information').then(response => {
        loader.hide()
        this.ram_used = response.body.ram_used
      })
    }
  }
}
</script>

<style lang="scss">
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
  border-radius: 10px;
  height: 450px;
  display: flex;
  flex-direction: column;
}

.card-radius {
  border-radius: 25px;
}

.field_button {
  width: 192px;
  margin-top: 20px;
}

.card:hover {
  box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.2);
}

.system {
  display: grid;
  grid-template-rows: 2fr 4fr;
}

.header {
  margin: 20px;
}

#ipValue {
  margin: 4px;
  padding: 5px 20px 5px 20px;
  background-color: whitesmoke;
  border-radius: 5px;
}

.speed_meter {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
}

#trafficValueSpan {
  font-size: 20px;
  padding: 5px 20px 5px 20px;
  background-color: whitesmoke;
  border-radius: 5px;
}

#actualSpeedCard {
  display: flex;
  flex-direction: column;
}

.speed_meter_btn {
  cursor: pointer;
  width: 50%;
}

.tachometer {
  font-size: 5rem;
  color: rgba(255, 0, 0, 0.541);
  justify-items: center;
}

.speed_value {
  height: 70%;
}

#actualSpeedCardOff {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.089);
  display: flex;
  flex-direction: column;
}

#systemInfoTable {
  padding: 30px 30px;
  text-align: center;
}

#staticColor {
  color: #00100c;
}

#sysInfo {
  color: #5a6f94;
  margin-top: 20px;
}

.speed_meter_main {
  overflow: hidden;
}

.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.loader-center {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: 131px 0 0 -350px;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.toCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  z-index: 2;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.speed_meter_text_st {
  font-size: 1.5rem;
  margin: 3rem;
  margin-top: 30%;
  color: #2c82e0;
}

.speed_meter_text_st_ {
  font-size: 1.5rem;
  margin: 3rem;
  margin-top: 30%;
  color: #2c83e031;
}

.speed_meter_text_dy {
  text-align: center;
  font-size: 3rem;
}

.speedometer {
  margin-top: 5%;
}

.width {
  width: 65%;
  margin-left: 50px;
}

.top {
  margin-top: 10px;
}

.speed_data {
  margin-top: 10px;
  margin-left: -11px;
}

.left {
  padding-left: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(300deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
